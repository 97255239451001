import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward30Icon from '@mui/icons-material/Forward30';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Typography from '@mui/material/Typography';

function PodcastEpisodePlayerComponent({ mp3_link, episode_name, release_date, autoPlay, demo = false }) {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(100);
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener('loadedmetadata', () => {
      setDuration(audioElement.duration);
    });

    if (autoPlay && audioElement) {
      audioElement.play().then(() => setPlaying(true)).catch((err) => console.log(err));
    }

    return () => {
      audioElement.removeEventListener('loadedmetadata', () => {
        setDuration(audioElement.duration);
      });
    };
  }, [mp3_link, autoPlay]);

  useEffect(() => {
    setCurrentTime(0);
    setPlaying(autoPlay);
  }, [mp3_link]);

  const togglePlayPause = () => {
    const isPlaying = !playing;
    setPlaying(isPlaying);
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleTimeSliderChange = (event, newValue) => {
    setCurrentTime(newValue);
    audioRef.current.currentTime = newValue;
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const skipTime = (amount) => {
    const newTime = audioRef.current.currentTime + amount;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', width: '95%' }}>
      <Typography variant={demo? "body2" : "h6"} align="center">{episode_name}</Typography>
      {!demo && (
  <Typography variant="body2" align="center">{release_date}</Typography>
)}
  
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <Typography variant="body2">{new Date(currentTime * 1000).toISOString().substr(11, 8)}</Typography>
        <Slider
          aria-label="time-slider"
          size="small"
          value={currentTime}
          min={0}
          step={1}
          max={duration}
          onChange={handleTimeSliderChange}
          sx={{ mx: 2, flexGrow: 1 }}
        />
        <Typography variant="body2">{new Date(duration * 1000).toISOString().substr(11, 8)}</Typography>
      </Box>
  
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, alignItems: 'center' }}>
        <IconButton onClick={() => skipTime(-10)}>
          <Replay10Icon />
        </IconButton>
        <IconButton onClick={togglePlayPause}>
          {playing ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton onClick={() => skipTime(30)}>
          <Forward30Icon />
        </IconButton>
  
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
          <VolumeUpIcon sx={{ mr: 1 }} />
          <Slider
            aria-label="volume-slider"
            value={volume}
            onChange={handleVolumeChange}
            sx={{ width: demo ? 50 : 100 }} // Conditional width based on the demo prop
            size="small"
          />
        </Box>
      </Box>
  
      <audio
        ref={audioRef}
        src={mp3_link}
        onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
        onEnded={() => setPlaying(false)}
        hidden
      />
    </Box>
  );
}

export default PodcastEpisodePlayerComponent;
