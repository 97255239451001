import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PodcastEpisodePlayerComponent from '../components/PodcastComponents/PodcastEpisodePlayerComponent';
import PodcastEpisodeCardComponent from '../components/PodcastComponents/PodcastEpisodeCardComponent';

function useFetchPodcastData() {
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPodcastData = async () => {
      try {
        const response = await fetch('/podcast_feeds.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Assuming the JSON structure has a key for Lex Fridman's podcast
        const lexFridmanPodcast = data.find(feed => feed.title === "Lex Fridman Podcast");
        setEpisodes(lexFridmanPodcast ? lexFridmanPodcast.entries : []);
      } catch (error) {
        console.error("Failed to load podcast data:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPodcastData();
  }, []);

  return { episodes, isLoading, error };
}

function AudioPlayer() {
  const { episodes, isLoading, error } = useFetchPodcastData();
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);

  const handleEpisodeSelect = useCallback((episode) => {
    setSelectedEpisode(episode);
    setAutoPlay(true);
  }, []);

  const episodesToDisplay = useMemo(() => episodes.slice(0, 10), [episodes]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading podcast data: {error.message}</div>;

  return (
    <div>
      <PodcastEpisodePlayerComponent
        mp3_link={selectedEpisode?.audio_link || ""}
        episode_name={selectedEpisode?.title || "Episode Title"}
        release_date={selectedEpisode ? new Date(selectedEpisode.published).toLocaleDateString("es-ES", {
          day: 'numeric', month: 'long', year: 'numeric'
        }) : ""}
        autoPlay={autoPlay}
        onAudioEnded={() => setAutoPlay(false)}
      />

      {episodesToDisplay.map((episode, index) => (
        <PodcastEpisodeCardComponent
          key={index}
          episode_image="/api/placeholder/400/320" // Placeholder image
          episode_title={episode.title || "Episode Title"}
          episode_release_date={new Date(episode.published).toLocaleDateString("es-ES", {
            day: 'numeric', month: 'long', year: 'numeric'
          })}
          episode_description={episode.summary || "Episode Description"}
          onEpisodeSelect={() => handleEpisodeSelect(episode)}
        />
      ))}
    </div>
  );
}

export default AudioPlayer;