import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import { useUser } from '../contexts/userContext'; // Ensure this path matches your project structure

function PageMementoMori() {
  const { userInfo, updateUserInfo } = useUser(); // Access JWT token from userInfo
  const [birthdate, setBirthdate] = useState(new Date());
  const [remember, setRemember] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  useEffect(() => {
    // Initialize birthdate from user context or localStorage
    if (userInfo && userInfo.birthdate) {
      setBirthdate(new Date(userInfo.birthdate));
    } else {
      const storedBirthdate = localStorage.getItem('birthdate');
      if (storedBirthdate) {
        setBirthdate(new Date(storedBirthdate));
        setRemember(true);
      }
    }
  }, [userInfo]);

  const handleDateChange = (date) => {
    setBirthdate(date);
    setShowUpdateButton(true);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
    setShowUpdateButton(true);
  };

  const handleUpdatePreferences = async () => {
    if (remember && birthdate) {
      localStorage.setItem('birthdate', birthdate.toISOString());
      // Send POST request to update birthday on the server
      try {
        const response = await fetch('https://boringtube.azurewebsites.net/api/birthday', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userInfo.jwtToken}`, // Use JWT token from user context
          },
          body: JSON.stringify({
            birthday: birthdate.toISOString().split('T')[0], // Format birthdate to "YYYY-MM-DD"
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update birthday');
        }

        const responseData = await response.json();
        console.log('Birthday updated successfully:', responseData);
      } catch (error) {
        console.error('Error updating birthday:', error);
      }
    } else {
      // If checkbox is unmarked, perform a "fake" request (log to console)
      console.log('Clearing birthdate (fake request)');
    }
    setShowUpdateButton(false);
  };

  const calculateAgeInWeeks = () => {
    const now = new Date();
    const born = new Date(birthdate);
    const weeks = Math.floor((now - born) / (1000 * 60 * 60 * 24 * 7));
    return weeks;
  };

  const generateMatrix = () => {
    const ageInWeeks = calculateAgeInWeeks();
    const rows = [];
    for (let y = 0; y < 80; y++) {
      const row = [];
      for (let x = 0; x < 52; x++) {
        const index = y * 52 + x;
        const isFilled = index < ageInWeeks;
        let style = {
          width: 10,
          height: 10,
          border: '1px solid black',
          margin: '1px',
          backgroundColor: isFilled ? 'grey' : 'white', // Adjusted colors here
        };
        // Add an extra space after the 26th square
        if (x === 25) {
          style.margin = '1px 10px 1px 1px';
        }
        row.push(<div key={x} style={style}></div>);
      }
      // Add the age label at the end of each line with smaller font size
      row.push(
        <div key="label" style={{ marginLeft: 8, fontSize: '8px', whiteSpace: 'nowrap' }}>
          {y + 1}
        </div>
      );
      // Add a top margin every ten lines
      const rowStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: y % 10 === 9 ? '10px' : '0',
      };
      rows.push(<div key={y} style={rowStyle}>{row}</div>);
    }
    return rows;
  };
  

  return (
    <div style={{ margin: 20 }}>
      <DatePicker
        selected={birthdate}
        onChange={handleDateChange}
        dateFormat="MMMM d, yyyy"
        className="form-control"
      />
      <FormControlLabel
        control={<Checkbox checked={remember} onChange={handleRememberChange} />}
        label="Remember birthdate"
      />
      {showUpdateButton && (
        <Button variant="contained" color="primary" onClick={handleUpdatePreferences}>
          Update Preferences
        </Button>
      )}
      <div style={{ marginTop: '20px', position: 'relative' }}>
        {generateMatrix()}
      </div>
    </div>
  );
}

export default PageMementoMori;
