/* global google */

import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Assuming jwtDecode is the correct import based on your previous code
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/userContext';
import { Button, Typography, Container, Grid, Paper } from '@mui/material';

const PageLogin = () => {
  const { userInfo, updateUserInfo } = useUser();
  const navigate = useNavigate();
  // Introducing state to manage visibility of the sign-out button based on user authentication status
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!userInfo.jwtToken);

  function handleSignout() {
    updateUserInfo({ jwtToken: null, user_name_bt: '', mode: 0, theme: 0, birthdate: null });
    document.getElementById("signInDiv").hidden = false;
    // Update the isUserLoggedIn state to false as user is logging out
    setIsUserLoggedIn(false);
  }

  async function verifyUser(token) {
    try {
      const response = await fetch('https://boringtube.azurewebsites.net/api/login', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const { success, data } = await response.json();
      if (success) {
        const { new_user, user_information, user_preferences } = data;
        updateUserInfo({
          jwtToken: token,
          user_name_bt: user_information.user_name_bt,
          mode: user_preferences.difficulty_mode,
          theme: user_preferences.darkmode ? 1 : 0,
          birthdate: user_information.birthday || null,
        });
        // User is successfully verified, so we set isUserLoggedIn to true
        setIsUserLoggedIn(true);
        if (new_user) {
          navigate('/onboarding');
        } else {
          navigate('/flow');
        }
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error verifying user:', error);
    }
  }

  function handleCallbackResponse(response) {
    const userObject = jwtDecode(response.credential); // This line seems to be unused, consider removing if not needed
    document.getElementById("signInDiv").hidden = true;
    verifyUser(response.credential);
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: "1056238697719-lsndlv63u1er4qo49bm66n9q2lqh3ult.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    );
    //google.accounts.id.prompt();

    // Initial check for user's login status upon component mount
    setIsUserLoggedIn(!!userInfo.jwtToken);
  }, [userInfo.jwtToken]); // Dependency array ensures this effect runs when userInfo.jwtToken changes

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '50px' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} textAlign="center">
            <Typography variant="h4" gutterBottom>
              Bienvenido a BoringTube
            </Typography>
            <Typography variant="body1">
              Inicia sesión para continuar
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <div id="signInDiv" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}></div>
          </Grid>
          {isUserLoggedIn && (
            <Grid item xs={12} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleSignout}>
                Sign Out
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
  
};

export default PageLogin;
