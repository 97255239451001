import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

function PodcastSeriesHeaderComponent({
  demo = false, // Set default value of demo to false
  series_name,
  series_author,
  series_description,
  series_image
}) {
  return (
    <Card sx={{ display: 'flex', my: 0 }}>
      <CardMedia
        component="img"
        sx={{
          width: demo ? 120 : 151, // Resize image based on demo flag
          height: demo ? 120 : 'auto' // Maintain aspect ratio for demo or use auto for normal
        }}
        image={series_image}
        alt={`Cover image for ${series_name}`}
      />
      <Box width={"10px"}> </Box>
      <Box>
        <CardContent sx={{ p: 0 }}> {/* Remove padding from CardContent */}
          <Typography component="h1" variant={demo ? "h6" : "h5"} sx={{ my: 0 }}>
            {series_name}
          </Typography>
          <Typography variant={demo ? "body2" : "subtitle1"} color="text.secondary" component="h2" sx={{ mt: 0.5, mb: 0 }}>
            {series_author}
          </Typography>
          <Typography variant={demo ? "body2" : "body1"} component="p" sx={{ my: 0 }}>
            {series_description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
  
  
}

export default PodcastSeriesHeaderComponent;
