import React, { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, IconButton, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const PodcastEpisodeCardComponent = ({
  episode_image,
  episode_title,
  episode_release_date,
  episode_description,
  onEpisodeSelect,
  demo = false // Assuming this prop controls demo mode
}) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [currentImage, setCurrentImage] = useState(episode_image);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const handleImageError = () => {
    setCurrentImage("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrfRL__onfbBmucTmzAwOmdV7ISJNxu_Dsew&usqp=CAU");
  };

  // Adjusting sizes and padding based on demo mode without introducing unwanted spacing
  const imageSize = demo ? 76 : 140;
  const typographyVariant = demo ? 'body2' : 'h6';
  const iconButtonSize = demo ? { width: 24, height: 24 } : { width: 48, height: 48 };
  const playIconSize = demo ? '1rem' : '2rem';
  const cardPadding = demo ? 0 : '16px'; // Remove extra padding in demo mode
  const boxMargin = demo ? '0px' : '0 16px'; // Adjusting to remove space below image and above title in demo mode

  return (
    <Card sx={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', alignItems: 'center' }}>
      <CardMedia
        component="img"
        sx={{ width: imageSize, height: imageSize, marginRight: boxMargin }}
        image={currentImage}
        alt="Episode Image"
        onError={handleImageError}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', padding: cardPadding }}>
        <CardContent sx={{ 
          flex: '1 0 auto', 
          "&:last-child": { paddingBottom: demo ? 0 : 2 }, 
          paddingTop: '4px', // Reduced padding above the title
          paddingBottom: '0px' // Remove bottom padding to tighten space if needed
        }}>
          <Typography variant={typographyVariant} component="div" gutterBottom>
            {episode_title}
          </Typography>
          {/* Always show release date */}
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {episode_release_date}
          </Typography>
          {!demo && (
            <Typography variant="body2" color="textSecondary" sx={{ 
              display: '-webkit-box', 
              overflow: 'hidden', 
              WebkitBoxOrient: 'vertical', 
              WebkitLineClamp: isDescriptionExpanded ? 'none' : 3, 
              cursor: 'pointer', 
              minHeight: '64px', // This reserves space for the description
            }} onClick={toggleDescription}>
              {isDescriptionExpanded ? episode_description : `${episode_description.substring(0, 300)}... Read More`}
            </Typography>
          )}
        </CardContent>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton aria-label="play" onClick={onEpisodeSelect} sx={iconButtonSize}>
          <PlayArrowIcon sx={{ fontSize: playIconSize }} />
        </IconButton>
      </Box>
    </Card>
  );
};

export default PodcastEpisodeCardComponent;
