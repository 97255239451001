import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Crisp } from "crisp-sdk-web";
import { UserProvider } from './contexts/userContext'; // Adjust the import path as necessary
import { FlowContentProvider } from './contexts/useFlowContent';
import { ClickProvider } from './contexts/useClickContent';
const container = document.getElementById('root');
const root = createRoot(container);

Crisp.configure("fd6b88b4-38eb-4ed6-a879-34925f2648f1");

root.render(
    //<React.StrictMode>
       <UserProvider>
        <ClickProvider>
        <FlowContentProvider>
        <App />
        </FlowContentProvider>
        </ClickProvider>
      </UserProvider>
    //</React.StrictMode>
);

