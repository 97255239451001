import React from 'react';
import { Box, Typography } from '@mui/material';
import ToolsCardComponent from '../components/ToolsComponenets/ToolsCardComponent';

const PageTools = () => {
  const tools = [
    {
      image: '/combine.png',
      title: 'Merge PDFs',
      description: 'Combine multiple PDF files into one easily.',
      link: '/tools/merge_pdfs'
    },
    {
      image: '/split.png',
      title: 'Split PDFs',
      description: 'Separate a PDF into multiple files quickly.',
      link: '/tools/split_pdfs'
    },
    {
      image: '/sign.png', 
      title: 'Sign PDFs',
      description: 'Easily add your signature to PDF documents.',
      link: '/tools/sign_pdfs'
    },
    {
      image: '/color_picker.png',
      title: 'Color Picker',
      description: 'Get the colors in your image',
      link: '/tools/color_picker'
    },
  ];

  return (
    <Box sx={{ backgroundColor: '#f5f5fa', padding: 2 }}>
      <Typography variant="h4" align="center" gutterBottom color="black">
        This is the tools page
      </Typography>

      <Typography variant="body1" align="center" color="black">
        Herramientas online para amantes de los PDF
      </Typography>
      <Typography variant="body1" align="center" color="black">
        Herramientas online y completamente gratuitas para unir PDF, separar PDF, comprimir PDF, convertir documentos Office a PDF, PDF a JPG y JPG a PDF. No se necesita instalación.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2,
          marginTop: 2,
        }}
      >
        {tools.map((tool, index) => (
          <ToolsCardComponent
            key={index}
            image={tool.image}
            title={tool.title}
            description={tool.description}
            link={tool.link}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PageTools;