import React, { createContext, useContext, useState, useEffect } from 'react';

const FlowContentContext = createContext();

export const FlowContentProvider = ({ children }) => {
  const [flowMode, setFlowMode] = useState(() => {
    const storedFlowMode = localStorage.getItem('flowMode');
    return storedFlowMode !== null ? JSON.parse(storedFlowMode) : 0;
  });

  useEffect(() => {
    localStorage.setItem('flowMode', JSON.stringify(flowMode));
  }, [flowMode]);

  const updateFlowMode = (mode) => {
    setFlowMode(mode);
    console.log('Flow mode updated to:', mode);
  };

  return (
    <FlowContentContext.Provider value={{ flowMode, updateFlowMode }}>
      {children}
    </FlowContentContext.Provider>
  );
};

export const useFlowContent = () => {
  const context = useContext(FlowContentContext);
  if (context === undefined) {
    throw new Error('useFlowContent must be used within a FlowContentProvider');
  }
  return context;
};
