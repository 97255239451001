import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MusicPlayer from '../components/MusicComponents/MusicPlayer';
import CarteleraComponent from '../components/MusicComponents/CarteleraComponent';

const PageMusic = () => {
  const [songsData, setSongsData] = useState({ songs: [] });
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    fetch('/songs/songs.json')
      .then(response => response.json())
      .then(data => setSongsData(data))
      .catch(error => console.error('Error loading songs:', error));
  }, []);

  const handleSongChange = (newIndex) => {
    setCurrentSongIndex(newIndex);
  };

  const handleCardClick = (index) => {
    setCurrentSongIndex(index);
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: '#080404', height: '100vh' }}>
      <Typography variant="h4" align="center" gutterBottom color="white">
        Music Player
      </Typography>

      {songsData.songs.length > 0 && (
        <MusicPlayer
          songs={songsData.songs}
          currentSongIndex={currentSongIndex}
          onSongChange={handleSongChange}
          autoPlay
          version={2}
        />
      )}

      <CarteleraComponent 
        title="Song List" 
        version={2} 
        songsData={songsData}
        onSongClick={handleCardClick}
      />
    </Box>
  );
};

export default PageMusic;