import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ToolsCardComponent = ({ image, title, description, link }) => {
  return (
    <Box
      component={Link}
      to={link}
      sx={{
        width: 200,
        height: 150,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        textDecoration: 'none', // Remove default link styling
        display: 'block', // Ensure it behaves as a block element
        '&:hover': {
          backgroundColor: '#f5f5fa',
          transform: 'translateY(-5px)', // Slight lift effect on hover
        },
      }}
    >
      <img
        src={image}
        alt={title}
        style={{
          width: '50px',
          height: '50px',
          position: 'absolute',
          top: 10,
          left: 10,
        }}
      />
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', marginTop: 1, paddingLeft: 1, paddingTop: '60px' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: '#555', paddingLeft: 1, paddingRight: 1 }}>
        {description}
      </Typography>
    </Box>
  );
};

export default ToolsCardComponent;