import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup, Box } from '@mui/material';

const TripleSwitch = () => {
    const [mode, setMode] = useState(() => {
        // Check if mode is stored in localStorage, otherwise set default value
        return localStorage.getItem('mode') || "Modo Avanzado";
    });

    useEffect(() => {
        // Update localStorage when mode changes
        localStorage.setItem('mode', mode);
    }, [mode]);

    const handleSwitchChange = (event) => {
        setMode(event.target.value);
    };

    return (
        <Box>
            <RadioGroup row value={mode} onChange={handleSwitchChange}>
                <FormControlLabel
                    value="Aprendiz"
                    control={<Radio />}
                    label="Modo Aprendiz"
                />
                <FormControlLabel
                    value="Avanzado"
                    control={<Radio />}
                    label="Modo Avanzado"
                />
                <FormControlLabel
                    value="Monje"
                    control={<Radio />}
                    label="Modo Monje"
                />
            </RadioGroup>
        </Box>
    );
};

export default TripleSwitch;
