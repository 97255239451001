import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Box, createTheme, ThemeProvider, CssBaseline, Switch } from '@mui/material';
import { useUser } from './contexts/userContext';

// Import your page components
import PageLogin from './pages/PageLogin';
import PageOnboarding from './pages/PageOnboarding';
import PageBoringPodcast from './pages/PageBoringPodcast';
import PageVideos from './pages/PageVideos';
import PageFlow from './pages/PageFlow';
import PageMementoMori from './pages/PageMementoMori';
import PageLanding from './pages/PageLanding';
import PageMusic from './pages/PageMusic';
//Tools
import PageTools from './pages/PageTools';
import PageColorPicker from './pages/PageColorPicker';

const Layout = () => {
  const { userInfo, updateUserInfo } = useUser();
  const location = useLocation(); // useLocation is now within Router context
  const isAuthenticated = !!userInfo.jwtToken;

  // Toggle theme function
  const toggleTheme = () => {
    updateUserInfo({ theme: userInfo.theme === 0 ? 1 : 0 });
  };

  const theme = createTheme({
    palette: {
      mode: userInfo.theme === 1 ? 'dark' : 'light',
    },
  });

  //ProtectedRoute component for handling route protection
const ProtectedRoute = ({ children }) => {
   return isAuthenticated ? children : <Navigate to="/login" replace />;
 };

  // Updated condition to exclude AppBar from onboarding and login pages
  const shouldShowAppBar = !['/', '/login', '/onboarding'].includes(location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        {shouldShowAppBar && (
          <AppBar position="static" elevation={0}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                BoringTube
              </Typography>
              {/*<Button color="inherit" component={Link} to="/podcast">Podcasts</Button>*/}
              {/*<Button color="inherit" component={Link} to="/discover-videos">Discover Videos</Button>*/}
              <Button color="inherit" component={Link} to="/flow">Flow</Button>
              <Button color="inherit" component={Link} to="/memento-mori">Memento Mori</Button>
              {isAuthenticated ? (
                <Button color="inherit" onClick={() => updateUserInfo({ jwtToken: null })}>Log Out</Button>
              ) : (
                <Button color="inherit" component={Link} to="/login">Login</Button>
              )}
            </Toolbar>
          </AppBar>
        )}
        <Container component="main" maxWidth="false" disableGutters={true}>
          <Routes>
            <Route path="/" element={<PageLanding />} />
            <Route path="/login" element={<PageLogin />} />
            <Route path="/onboarding" element={<ProtectedRoute><PageOnboarding /></ProtectedRoute>} />
            <Route path="/podcast" element={<PageBoringPodcast />} />
            <Route path="/discover-videos" element={<ProtectedRoute><PageVideos /></ProtectedRoute>} />
            <Route path="/flow" element={<ProtectedRoute><PageFlow /></ProtectedRoute>} />
            <Route path="/memento-mori" element={<ProtectedRoute><PageMementoMori /></ProtectedRoute>} />
            <Route path="/music" element={<PageMusic />} />
            <Route path="/tools" element={<PageTools />} />
            <Route path='/tools/color_picker' element={<PageColorPicker/>}/>
          </Routes>
        </Container>
        <Box
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
            color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center' }}>
            <Button color="inherit" component="a" href="https://evergreen-tarn-9a2.notion.site/Art-culos-de-BoringTube-188ecaf98eba47e2814c31d2f91830c8?pvs=4" target="_blank" rel="noopener noreferrer">BoringTube Articles</Button>
            <Button color="inherit" component="a" href="https://stats.uptimerobot.com/oKW7m9ZCU2" target="_blank" rel="noopener noreferrer">Uptime Stats</Button>
            <Switch checked={userInfo.theme === 1} onChange={toggleTheme} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Layout />} />
      </Routes>
    </Router>
  );
}

export default App;
