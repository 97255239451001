import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box, Typography } from '@mui/material';
import TripleSwitch from '../../components/ModoComponent';

const initialVideos = [
    { channel: "National Geographic", title: "Costa Rica", link: "https://www.youtube.com/watch?v=LXb3EKWsInQ" },
    { channel: "Adrià Solà Pastor", title: "Por qué siempre estás cansado? Triplica tu energía y vitalidad, según la ciencia", link: "https://www.youtube.com/watch?v=vgWchSB-hlU" },
    { channel: "Adrià Solà Pastor", title: "Cómo invertir para principiantes - Guía Completa Paso a Paso (2024)", link: "https://www.youtube.com/watch?v=Jo8oXe_C558" },
    { channel: "Hamza Ahmed", title: "How to Win Friends and Influence People (EVERY PRINCIPLE)", link: "https://www.youtube.com/watch?v=r8U3bujUq4Y" },
    { channel: "Hamza Unfiltered", title: "How to become a youtuber", link: "https://www.youtube.com/watch?v=BA6N_gbm_kM" },
    { channel: "Think Media", title: "MrBeast Shares His Best YouTube Advice", link: "https://www.youtube.com/watch?v=9DBJXRy5dvk" },
    { channel: "University of Virginia School of Law", title: "Secrets to Optimal Client Service, With Jim Donovan", link: "https://www.youtube.com/watch?v=hJbwyN4ZoCg" },
    { channel: "Sergio Begueria", title: "Suiza: El País Perfecto para hacerte Rico Siendo Joven", link: "https://www.youtube.com/watch?v=3cutz45sE_Q" },
    { channel: "Fireship", title: "How to get rich as a solo software developer - The Ultimate Guide", link: "https://www.youtube.com/watch?v=A4_TFHzqAAg" },
    { channel: "Y Combinator", title: "Sam Altman - How to Succeed with a Startup", link: "https://www.youtube.com/watch?v=0lJKucu6HJc" },
    // Add more videos here if needed
];

const PageDiscoverVideos = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [mode, setMode] = useState('aprendiz'); // Default mode

    useEffect(() => {
        const storedMode = localStorage.getItem('mode');
        setMode(storedMode);
        console.log("yuhuuu" + storedMode)
    }, []);

    const handleVideoClick = (video) => {
        setSelectedVideo(video);
    };

    if (mode === 'Monje') {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <TripleSwitch />

            <Typography>This mode is not available yet</Typography>
        </Box>;
    }

    if (mode === 'Aprendiz') {
        return (
            
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
            <TripleSwitch />
                {initialVideos.map((video, index) => (
                    <ReactPlayer key={index} url={video.link} controls style={{ marginBottom: '20px' }} />
                ))}
            </Box>
        );
    }

    // For 'Avanzado' and other modes (default rendering)
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
            <TripleSwitch />
            <Box sx={{ width: '100%', maxWidth: 800, marginBottom: 2 }} justifyContent="center">
                {
                    selectedVideo
                        ? <ReactPlayer url={selectedVideo.link} controls />
                        : <ReactPlayer url="https://www.youtube.com/watch?v=ubFq-wV3Eic" controls />
                }
            </Box>
            <ReactPlayer url="https://rumble.com/embed/v4ll0v5/?pub=4" controls />  
            <iframe class="rumble" width="640" height="360" src="https://rumble.com/embed/v4ll0v5/?pub=4" frameborder="0" allowfullscreen></iframe>
            {initialVideos.map((video, index) => (
                <Typography key={index} variant="subtitle1" onClick={() => handleVideoClick(video)} sx={{ cursor: 'pointer', marginBottom: 1, width: '100%', maxWidth: 800, backgroundColor: selectedVideo === video ? '#f0f0f0' : 'transparent' }}>
                    {video.channel} | {video.title}
                </Typography>
            ))}
        </Box>
    );
};

export default PageDiscoverVideos;
