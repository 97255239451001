import React, { useState } from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const defaultImage = "/songs/images/fallback.jpg";

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  margin: '5px',
  borderRadius: '8px',
  cursor: 'pointer',
  backgroundColor: '#080404',  // Set the background color to #080404
  color: 'white',  // Ensure text color is white for better contrast
}));

const SongInfo = styled(CardContent)(() => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '8px',
}));

const ScrollableContainer = styled(Box)(() => ({
  display: 'flex',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
}));

const SongCard = ({ song, version, onClick }) => {
  if (version === 1) {
    return (
      <StyledCard sx={{ flexDirection: 'column', width: 180, height: 220 }} onClick={onClick}>
        <CardMedia
          component="img"
          image={song.image || defaultImage}
          alt={`${song.title} album cover`}
          sx={{ height: 170, width: '100%' }}
        />
        <SongInfo>
          <Typography variant="subtitle1" fontWeight="bold" noWrap sx={{ fontFamily: 'Roboto, sans-serif' }}>{song.title}</Typography>
          <Typography variant="body2" noWrap sx={{ marginTop: '4px', color: 'white', fontFamily: 'Roboto, sans-serif' }}>{song.artist}</Typography>
        </SongInfo>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard sx={{ height: 70 }} onClick={onClick}>
        <CardMedia
          component="img"
          image={song.image || defaultImage}
          alt={`${song.title} album cover`}
          sx={{ width: 70, height: '100%' }}
        />
        <SongInfo>
          <Typography variant="subtitle1" fontWeight="bold" noWrap sx={{ fontFamily: 'Roboto, sans-serif' }}>{song.title}</Typography>
          <Typography variant="body2" noWrap sx={{ marginTop: '4px', color: 'white', fontFamily: 'Roboto, sans-serif' }}>
            {song.artist} • {song.views.toLocaleString()} views
          </Typography>
        </SongInfo>
      </StyledCard>
    );
  }
};

const CarteleraComponent = ({ title, version = 1, songsData, onSongClick }) => {
  const songs = songsData.songs || [];
  const [page, setPage] = useState(0);
  const songsPerPage = 12; // 3 columns * 4 rows
  const pageCount = Math.ceil(songs.length / songsPerPage);

  const handleNextPage = () => {
    setPage((prevPage) => (prevPage + 1) % pageCount);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => (prevPage - 1 + pageCount) % pageCount);
  };

  const displayedSongs = songs.slice(page * songsPerPage, (page + 1) * songsPerPage);

  const handleSongClick = (index) => {
    if (onSongClick && typeof onSongClick === 'function') {
      onSongClick(index + page * songsPerPage); // Correctly calculate the actual index
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ margin: '10px 0 5px 10px' }}>
        <Typography variant="h6" fontWeight="bold" color="white" sx={{ fontFamily: 'Roboto, sans-serif' }}>{title}</Typography>
        {pageCount > 1 && (
          <Box>
            <IconButton onClick={handlePrevPage} disabled={page === 0} sx={{ color: 'white' }}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton onClick={handleNextPage} disabled={page === pageCount - 1} sx={{ color: 'white' }}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {version === 1 ? (
        <ScrollableContainer>
          {displayedSongs.map((song, index) => (
            <SongCard key={index} song={song} version={version} onClick={() => handleSongClick(index)} />
          ))}
        </ScrollableContainer>
      ) : (
        <Grid container spacing={1}>
          {displayedSongs.map((song, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SongCard song={song} version={version} onClick={() => handleSongClick(index)} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default CarteleraComponent;
