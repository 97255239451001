import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import StatisticsChart from './StatisticsComponent'; // Adjust the import path as needed
import { useUser } from '../../contexts/userContext'; // Adjust the import path as needed
import BarChartIcon from '@mui/icons-material/BarChart'; // Graph icon

const TimerComponent = () => {
  const { userInfo } = useUser(); // Make sure to adjust this based on your actual context
  const [timerType, setTimerType] = useState('Pomodoro');
  const [secondsLeft, setSecondsLeft] = useState(25 * 60);
  const [isActive, setIsActive] = useState(false);
  const [openStatistics, setOpenStatistics] = useState(false);

  // Timer types and their respective durations
  const timerTypes = {
    'Pomodoro': 25 * 60,
    'Pausa Corta': 5 * 60,
    'Pausa Larga': 15 * 60,
  };

  useEffect(() => {
    let intervalId = null;
    if (isActive && secondsLeft > 0) {
      intervalId = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (isActive && secondsLeft === 0) { // Only call handleTimerEnd if the timer is active
      handleTimerEnd();
      setIsActive(false);
    }
    return () => clearInterval(intervalId);
  }, [isActive, secondsLeft]);
  

  const handleTimerTypeChange = (type) => {
    setTimerType(type);
    setSecondsLeft(timerTypes[type]);
    setIsActive(false);
  };

  const handleStartStopClick = () => {
    setIsActive(!isActive);
  };

  const formatTime = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleTimerEnd = async () => {
    // Play the sound from the provided URL
    const audio = new Audio('https://boringtube.blob.core.windows.net/public/pomodoro_bell.wav');
    audio.play().catch(error => console.error('Failed to play:', error));
    // Define your API URL and payload structure
    const apiURL = 'https://boringtube.azurewebsites.net/api/record_pomodoro';
    const timerTypeMap = { 'Pomodoro': 0, 'Pausa Corta': 1, 'Pausa Larga': 2 };

    const payload = {
      type: timerTypeMap[timerType] ?? 0, // Default to 'Pomodoro' if type is not found
      end_time: new Date().toISOString(),
    };

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userInfo.jwtToken}`, // Use JWT token from user context
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log('Timer recorded successfully:', data);
    } catch (error) {
      console.error('Failed to record timer:', error);
    }
  };

  const handleOpenStatistics = () => {
    setOpenStatistics(true);
  };

  const handleCloseStatistics = () => {
    setOpenStatistics(false);
  };

  return (
    <Box sx={{
      border: '2px solid',
      borderColor: "black",
      backgroundColor: "white",
      borderRadius: '20px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      maxWidth: '400px',
      margin: 'auto',
    }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {Object.keys(timerTypes).map((type) => (
          <Button
            key={type}
            variant={timerType === type ? 'contained' : 'outlined'}
            onClick={() => handleTimerTypeChange(type)}
            sx={{
              fontSize: '0.75rem',
              padding: '6px 12px',
              ...(timerType === type && {
                backgroundColor: '#D9D9D9',
                color: 'black',
              }),
            }}
          >
            {type}
          </Button>
        ))}
      </Box>
      <Typography variant="h3" component="div" sx={{ flexGrow: 1, fontSize: '6rem', color: 'black', textAlign: 'center' }}>
        {formatTime()}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, width: '100%' }}>
        <Button
          variant="contained"
          onClick={handleStartStopClick}
          sx={{ padding: '6px 24px', fontSize: '0.875rem', flexShrink: 0 }}
          disabled={secondsLeft <= 0} // Disable the button when secondsLeft is 0 or less
        >
          {isActive ? 'Parar' : 'Empezar'}
        </Button>
        <Box sx={{ flexGrow: 1 }} /> {/* Empty box to push the button to the center */}
        <IconButton onClick={handleOpenStatistics} sx={{ flexShrink: 0 }}>
          <BarChartIcon />
        </IconButton>
      </Box>
      {/* Statistics Modal */}
      <Modal
        open={openStatistics}
        onClose={handleCloseStatistics}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openStatistics}>
          <Box
            sx={{
              outline: 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600, // Increased size
              height: 'auto', // Adjust height based on content or set a fixed value
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto', // In case the content overflows
            }}
          >
            <StatisticsChart />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
  
  
  
  
  
  
  
  
};

export default TimerComponent;