import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper, Typography, Button } from '@mui/material';

// Assuming these components are in the same directory
import PodcastEpisodeCardComponent from '../PodcastComponents/PodcastEpisodeCardComponent';
import PodcastEpisodePlayerComponent from '../PodcastComponents/PodcastEpisodePlayerComponent';
import PodcastSeriesHeaderComponent from '../PodcastComponents/PodcastSeriesHeaderComponent';

import DemoPomodoroComponent from '../LandingComponents/DemoPomodoroComponent';
import DemoFlowPlayerComponent from '../LandingComponents/DemoFlowPlayerComponent';

export default function DemoComponent() {
  const podcastData = [
    {
      title: "Ha Visto Morir a +1000 Personas: 5 Aprendizajes de Vida",
      image: "https://megaphone.imgix.net/podcasts/17b805a0-725f-11ee-9438-9ff7e57cc278/image/3010066-1673824048433-8be03196cb427.jpg?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress",
      pubDate: new Date("Fri, 29 Mar 2024 11:00:00 -0000"),
      mp3Link: "https://traffic.megaphone.fm/HOT9674207548.mp3?updated=1711623566"
    },
    {
      title: "7 Claves para que tu Negocio Triunfe en 2024 (Experto eCommerce)",
      image: "https://megaphone.imgix.net/podcasts/17b805a0-725f-11ee-9438-9ff7e57cc278/image/3010066-1673824048433-8be03196cb427.jpg?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress",
      pubDate: new Date("Mon, 25 Mar 2024 11:00:00 -0000"),
      mp3Link: "https://traffic.megaphone.fm/HOT6728127478.mp3?updated=1711448932"
    },
    {
      title: "De No Tener Clientes a +80 Locales en 5 Años (Caso Goiko)",
      image: "https://megaphone.imgix.net/podcasts/17b805a0-725f-11ee-9438-9ff7e57cc278/image/3010066-1673824048433-8be03196cb427.jpg?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress",
      pubDate: new Date("Thu, 21 Mar 2024 11:00:00 -0000"),
      mp3Link: "https://traffic.megaphone.fm/HOT9136799747.mp3?updated=1711115177"
    },
  ];
  const [value, setValue] = useState(0);
  const [selectedEpisode, setSelectedEpisode] = useState(podcastData[0]);
  const [autoPlay, setAutoPlay] = useState(false);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEpisodeSelect = (episode) => {
    setSelectedEpisode(episode);
    setAutoPlay(true);
  };

  return (
    <Paper elevation={0} sx={{
      width: 440,
      height: 440,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '16px',
      border: '4px dashed',
      borderColor: 'black.500',
      padding: '8px',
      bgcolor: 'lightgrey',
    }}>
      <Tabs value={value} onChange={handleChange} centered sx={{ width: '100%' }}>
        <Tab label="Podcast" />
        <Tab label="Flow" />
        <Tab label="Y más ..." />

      </Tabs>
      <Box sx={{
        width: 430,
        maxHeight: 440,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: '12px',
        overflowY: 'auto',
      }}>
        {value === 0 && (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <PodcastSeriesHeaderComponent
              demo={true}

              series_name="Tengo un Plan"
              series_author="Sergio Beguería y Juan Domínguez"
              series_description="Un podcast para gente emprendedora e inconformista."
              series_image="https://megaphone.imgix.net/podcasts/17b805a0-725f-11ee-9438-9ff7e57cc278/image/3010066-1673824048433-8be03196cb427.jpg"
            />
            {selectedEpisode && (
              <PodcastEpisodePlayerComponent
              demo={true}
                mp3_link={selectedEpisode.mp3Link}
                episode_name={selectedEpisode.title}
                release_date={selectedEpisode.pubDate.toLocaleDateString("en-US", {
                  day: 'numeric', month: 'long', year: 'numeric'
                })}
                autoPlay={autoPlay}
              />
            )}
            {podcastData.map((episode, index) => (
              <PodcastEpisodeCardComponent
                demo={true}
                key={index}
                episode_image={episode.image}
                episode_title={episode.title}
                episode_release_date={episode.pubDate.toLocaleDateString("en-US", {
                  day: 'numeric', month: 'long', year: 'numeric'
                })}
                episode_description=""
                onEpisodeSelect={() => handleEpisodeSelect(episode)}
              />
            ))}

          </Box>
        )}
        
       {value === 1 && (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundImage: 'url("./flow_background.png")', // Replace path_to_your_image.jpg with the actual path to your image
      backgroundSize: 'cover', // This ensures that the background covers the available space
      backgroundPosition: 'center', // This centers the background image
    }}
  >
            
            <DemoPomodoroComponent  /> 

            <DemoFlowPlayerComponent />

  </Box>
)}


{value === 2 && (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Descube muchas más funcionalidades al registrarte</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>BoringTube está en Beta Cerrada, lo cual significa que unos pocos usuarios tendrán acceso a la plataforma con el fin de probar las funcionalidades y proponer mejoras. Si estás en la beta privada, tendrás la oportunidad de acceder a los servicios de BoringTube de manera gratuita.   </Typography>
            
            <Typography variant="body1" sx={{ mb: 2 }}>Si te unes, cambiarás tu vida, y ayudarás en la luchas contra la adicción del contenido online. Tu feedback nos importa mucho. </Typography>

            
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6">↓ Únete a la beta privada ↓</Typography>
            <Button
              variant="contained"
              size="large"
              href="/login"
              sx={{
                bgcolor: 'primary.main', // Use primary color for a standard look
                '&:hover': {
                  bgcolor: 'primary.dark', // Darken the button on hover for feedback
                },
                fontSize: '0.8rem', // Adjust font size to standard
                padding: '10px 10px', // Standard padding for a normal appearance
              }}
            >
      Acceder
    </Button  > 
          </ Box>
          <Box sx={{ marginTop:"20px",display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h5">🍷🗿</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Cambia tu vida
            </Typography>
          </Box>
          <Typography variant="h5">🤝</Typography>

          

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">💪😎</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Ayuda en la lucha
            </Typography>
          </Box>
          
        </Box>


          </Box>

        )}

      </Box>
    </Paper>
  );
}
