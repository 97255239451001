import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Modal, Backdrop, Fade } from '@mui/material';
//import StatisticsChart from './StatisticsComponent'; // Adjust the import path as needed

const DemoPomodoroComponent = () => {
  const [timerType, setTimerType] = useState('Pomodoro');
  const [secondsLeft, setSecondsLeft] = useState(25 * 60);
  const [isActive, setIsActive] = useState(false);

  // Timer types and their respective durations
  const timerTypes = {
    'Pomodoro': 25 * 60,
    'Pausa Corta': 5 * 60,
    'Pausa Larga': 15 * 60,
  };

  useEffect(() => {
    let intervalId = null;
    if (isActive && secondsLeft > 0) {
      intervalId = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (isActive && secondsLeft === 0) {
      setIsActive(false);
    }
    return () => clearInterval(intervalId);
  }, [isActive, secondsLeft]);

  const handleTimerTypeChange = (type) => {
    setTimerType(type);
    setSecondsLeft(timerTypes[type]);
    setIsActive(false);
  };

  const handleStartStopClick = () => {
    setIsActive(!isActive);
  };

  const formatTime = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Box sx={{
      border: '2px solid',
      borderColor: "black",
      backgroundColor: "white",
      borderRadius: '5px',
      padding: '2px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      maxWidth: '300px',
      margin: 'auto',
    }}>
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
        {Object.keys(timerTypes).map((type) => (
          <Button
            key={type}
            variant={timerType === type ? 'contained' : 'outlined'}
            onClick={() => handleTimerTypeChange(type)}
            sx={{
              fontSize: '0.6rem', // Adjusted for smaller text
              padding: '1px 4px', // Reduced padding for less bulk
              ...(timerType === type && {
                backgroundColor: '#D9D9D9',
                color: 'black',
              }),
            }}
          >
            {type}
          </Button>
        ))}
      </Box>
      <Typography variant="h3" component="div" sx={{ flexGrow: 1, fontSize: '3rem', color: 'black', textAlign: 'center' }}>
        {formatTime()}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, width: '100%' }}>
        <Button
          variant="contained"
          onClick={handleStartStopClick}
          sx={{
            padding: '4px 18px', // Adjusted padding
            fontSize: '0.75rem', // Adjusted font size
          }}
          disabled={secondsLeft <= 0}
        >
          {isActive ? 'Parar' : 'Empezar'}
        </Button>
      </Box>
    </Box>
  );
};

export default DemoPomodoroComponent;
