import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useUser } from '../../contexts/userContext'; // Adjust this import path based on your project structure

const StatisticsChart = () => {
  const [sessionsData, setSessionsData] = useState([]);
  const { userInfo } = useUser(); // Assuming userInfo contains the JWT token
  const chartRef = useRef(null); // useRef to track the Chart.js instance

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://boringtube.azurewebsites.net/api/pomodoro_statistics', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userInfo.jwtToken}`, // Use the JWT token from user context
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setSessionsData(data.data.sessions_last_week);
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    if (userInfo && userInfo.jwtToken) {
      fetchData();
    }
  }, [userInfo.jwtToken]); // Dependency array ensures fetch is called when token updates

  useEffect(() => {
    if (sessionsData.length) {
      // Clean up the previous chart before drawing a new one
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      
      const ctx = document.getElementById('myChart');
      if (!ctx) return;

      const processedData = sessionsData.reduce((acc, { date, duration, session_type }) => {
        if (!acc[date]) acc[date] = { 0: { duration: 0, count: 0 }, 1: { duration: 0, count: 0 }, 2: { duration: 0, count: 0 } };
        acc[date][session_type].duration += duration;
        acc[date][session_type].count += 1;
        return acc;
      }, {});

      const chartData = {
        labels: Object.keys(processedData),
        datasets: [{
            label: 'Pomodoro',
            data: Object.values(processedData).map(data => data[0].duration),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          }, {
            label: 'Short Break',
            data: Object.values(processedData).map(data => data[1].duration),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
          }, {
            label: 'Long Break',
            data: Object.values(processedData).map(data => data[2].duration),
            backgroundColor: 'rgba(255, 206, 86, 0.5)',
          }],
      };

      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            datalabels: {
              color: '#000',
              anchor: 'center', // Center labels within each bar
              align: 'center',
              formatter: function(value, context) {
                const dataIndex = context.dataIndex;
                const sessionType = context.datasetIndex;
                const count = Object.values(processedData)[dataIndex][sessionType].count;
                return count > 0 ? `${count}x` : null; // Only display labels for non-zero counts
              }
            }
          },
          scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true },
          },
        },
        
        plugins: [ChartDataLabels],
      });
    }
  }, [sessionsData]);

  // Conditional rendering based on sessionsData length
  if (!sessionsData.length) {
    return <div style={{ width: '800px', maxWidth: '100%', textAlign: 'center' }}>No has hecho ningún pomodoro los últimos 7 días</div>;
  }

  return (
    <div style={{ width: '800px', maxWidth: '100%' }}>
      <canvas id="myChart" />
    </div>
  );
};

export default StatisticsChart;
