import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Slider, Typography, IconButton, Popover } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const DemoFlowPlayerComponent = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(70);
  const [isMuted, setIsMuted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For the Popover

  const audioRef = useRef(new Audio());

  const songs = [
    // Add your songs data here as provided
    {
        "duration": "02:48",
        "genre": "Cartoon",
        "release_date": "2024-03-27",
        "song_attribution": "[non copyright music] Lofi Type Beat - stir | Midsummer YouTube video link : https://www.youtube.com/watch?v=lrHztXruyS4&pp=ygUPbWlkc3VtbWVyIGxvLWZp",
        "song_audio_link": "https://boringtube.blob.core.windows.net/songs/stir.mp3",
        "song_author": "Midsummer",
        "song_image_link": "https://boringtube.blob.core.windows.net/images/stir.png",
        "song_name": "stir"
    },
    {
        "duration": "02:48",
        "genre": "Cartoon",
        "release_date": "2024-03-22",
        "song_attribution": "[non copyright music] Lofi Type Beat - flood | Lofiru Link : https://www.youtube.com/watch?v=MNFXyhnB-HE&pp=ygUGbG9maXJ1",
        "song_audio_link": "https://boringtube.blob.core.windows.net/songs/flood.mp3",
        "song_author": "Lofiru",
        "song_image_link": "https://boringtube.blob.core.windows.net/images/flood.png",
        "song_name": "flood"
    },
    {
        "duration": "03:29",
        "genre": "Cartoon",
        "release_date": "2024-03-22",
        "song_attribution": "[non copyright music] Lofi Type Beat - japan | Lofiru Link : https://www.youtube.com/watch?v=F5nbdXUqe9k&pp=ygUGbG9maXJ1",
        "song_audio_link": "https://boringtube.blob.core.windows.net/songs/japan.mp3",
        "song_author": "Lofiru",
        "song_image_link": "https://boringtube.blob.core.windows.net/images/japan.png",
        "song_name": "japan"
    },
    // Add the rest of your songs here...
];
useEffect(() => {
  audioRef.current.src = songs[currentSongIndex].song_audio_link;
  audioRef.current.load();
  if (isPlaying) {
    audioRef.current.play().catch(console.error);
  }
  return () => { // This function gets called when the component unmounts
    audioRef.current.pause();
    audioRef.current.src = ""; // Optionally clear the source
  };
}, [currentSongIndex, isPlaying]);

useEffect(() => {
  audioRef.current.volume = isMuted ? 0 : volume / 100;
}, [volume, isMuted]);

const togglePlayPause = () => {
  setIsPlaying(!isPlaying);
};

const toggleMute = () => {
  setIsMuted(!isMuted);
};

const handleVolumeChange = (event, newValue) => {
  setVolume(newValue);
};

const skipSong = () => {
  setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
};
const handleAttributionClick = (event) => {
  setAnchorEl(anchorEl ? null : event.currentTarget);
};
 // Determines if the Popover is open based on whether anchorEl is set
 const open = Boolean(anchorEl);
 // Unique id for the Popover; only defined if open is true
 const id = open ? 'song-attribution-popover' : undefined;
return (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    borderRadius: '16px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    backgroundColor: '#f5f5f5',
    width: '200px',
    my: 2
  }}>
    <Button
      onClick={togglePlayPause}
      sx={{
        my: 2,
        '& .MuiSvgIcon-root': {
          fontSize: '4rem',
        }
      }}
    >
      {isPlaying ? <PauseCircleFilledIcon /> : <PlayCircleFilledWhiteIcon />}
    </Button>
    
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 0,
      width: '70%',
    }}>
      <IconButton
        onClick={toggleMute}
        sx={{
          padding: '6px',
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          }
        }}
      >
        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
      <Slider
        aria-label="Volume"
        value={volume}
        onChange={handleVolumeChange}
        min={0}
        max={100}
        sx={{
          flexGrow: 1,
          mx: 2,
          '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
          },
          '& .MuiSlider-track, & .MuiSlider-rail': {
            height: 2,
          },
        }}
      />
      <IconButton
        onClick={skipSong}
        sx={{
          padding: '6px',
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          }
        }}
      >
        <SkipNextIcon />
      </IconButton>
    </Box>
  
    <Typography variant="body2" sx={{ cursor: 'pointer', color: 'grey' }} onClick={handleAttributionClick}>
      Song Attribution
    </Typography>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleAttributionClick}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Typography sx={{ p: 2 }}>{songs[currentSongIndex].song_attribution}</Typography>
    </Popover>
  </Box>
  
);
};

export default DemoFlowPlayerComponent;