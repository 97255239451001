import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, Typography, Paper, Button, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileLoaderComponent from '../components/ToolsComponenets/FileLoaderComponent';

const ColorPickerPage = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [colorPalette, setColorPalette] = useState([]);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const zoomCanvasRef = useRef(null);

  const handleFileLoad = (file, fileContent) => {
    setUploadedImage(fileContent);
    setSelectedColor(null);
    setColorPalette([]);
  };

  const handleImageLoad = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = imageRef.current.naturalWidth;
    canvas.height = imageRef.current.naturalHeight;
    ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
    generateColorPalette(ctx, canvas.width, canvas.height);
  };

  const generateColorPalette = (ctx, width, height) => {
    const imageData = ctx.getImageData(0, 0, width, height).data;
    const colorCounts = {};
    const samplingRate = 1; // Sample every 5th pixel

    for (let i = 0; i < imageData.length; i += 4 * samplingRate) {
      const r = Math.round(imageData[i] / 8) * 8;
      const g = Math.round(imageData[i + 1] / 8) * 8;
      const b = Math.round(imageData[i + 2] / 8) * 8;
      const rgb = `rgb(${r},${g},${b})`;

      colorCounts[rgb] = (colorCounts[rgb] || 0) + 1;
    }

    const sortedColors = Object.entries(colorCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 8)
      .map(([color]) => color);

    setColorPalette(sortedColors);
  };
  const handleCanvasClick = useCallback((e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = Math.floor((e.clientX - rect.left) * scaleX);
    const y = Math.floor((e.clientY - rect.top) * scaleY);
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(x, y, 1, 1).data;
    const color = `rgb(${imageData[0]},${imageData[1]},${imageData[2]})`;
    setSelectedColor(color);
  }, []);

  const handleCanvasMouseMove = useCallback((e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = Math.floor((e.clientX - rect.left) * scaleX);
    const y = Math.floor((e.clientY - rect.top) * scaleY);
    setMousePosition({ x, y });
  }, []);

  useEffect(() => {
    const drawZoom = () => {
      const mainCanvas = canvasRef.current;
      const zoomCanvas = zoomCanvasRef.current;
      if (!mainCanvas || !zoomCanvas || !uploadedImage) return;

      const ctx = mainCanvas.getContext('2d');
      const zoomCtx = zoomCanvas.getContext('2d');

      const zoomFactor = 10;
      const zoomSize = 21;

      zoomCtx.imageSmoothingEnabled = false;

      zoomCtx.clearRect(0, 0, zoomCanvas.width, zoomCanvas.height);

      const drawX = Math.max(0, Math.min(mousePosition.x - Math.floor(zoomSize / 2), mainCanvas.width - zoomSize));
      const drawY = Math.max(0, Math.min(mousePosition.y - Math.floor(zoomSize / 2), mainCanvas.height - zoomSize));

      zoomCtx.drawImage(
        mainCanvas,
        drawX,
        drawY,
        zoomSize,
        zoomSize,
        0,
        0,
        zoomCanvas.width,
        zoomCanvas.height
      );

      zoomCtx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
      for (let i = 0; i <= zoomSize; i++) {
        zoomCtx.beginPath();
        zoomCtx.moveTo(i * (zoomCanvas.width / zoomSize), 0);
        zoomCtx.lineTo(i * (zoomCanvas.width / zoomSize), zoomCanvas.height);
        zoomCtx.stroke();
        zoomCtx.beginPath();
        zoomCtx.moveTo(0, i * (zoomCanvas.height / zoomSize));
        zoomCtx.lineTo(zoomCanvas.width, i * (zoomCanvas.height / zoomSize));
        zoomCtx.stroke();
      }

      const centerPixelSize = zoomCanvas.width / zoomSize;
      zoomCtx.strokeStyle = 'red';
      zoomCtx.strokeRect(
        Math.floor(zoomCanvas.width / 2 - centerPixelSize / 2),
        Math.floor(zoomCanvas.height / 2 - centerPixelSize / 2),
        centerPixelSize,
        centerPixelSize
      );
    };

    drawZoom();
  }, [mousePosition, uploadedImage]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const getRgbRange = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g).map(Number);
    const minR = Math.max(0, r - 1);
    const maxR = Math.min(255, r + 1);
    const minG = Math.max(0, g - 1);
    const maxG = Math.min(255, g + 1);
    const minB = Math.max(0, b - 1);
    const maxB = Math.min(255, b + 1);

    return `rgb(${minR}-${maxR}, ${minG}-${maxG}, ${minB}-${maxB})`;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      <Paper elevation={3} sx={{ width: '100%', maxWidth: '1200px', p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
          Color Picker
        </Typography>
        
        <Box sx={{ width: '50%', mb: 3, mx: 'auto' }}>
          <FileLoaderComponent
            tool_name="Image Color Picker"
            button_text="Upload Image"
            tool_description="Upload an image to pick colors from it."
            onFileLoad={handleFileLoad}
            acceptedFileTypes=".jpg,.jpeg,.png"
          />
        </Box>

        {uploadedImage && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <img 
                  ref={imageRef}
                  src={uploadedImage} 
                  alt="Uploaded"
                  style={{ display: 'none' }}
                  onLoad={handleImageLoad}
                />
                <canvas 
                  ref={canvasRef}
                  style={{ maxWidth: '100%', height: 'auto', cursor: 'crosshair' }}
                  onClick={handleCanvasClick}
                  onMouseMove={handleCanvasMouseMove}
                />
                <Box sx={{ position: 'absolute', right: 10, bottom: 10, bgcolor: 'white', border: '1px solid black' }}>
                  <canvas
                    ref={zoomCanvasRef}
                    width={210}
                    height={210}
                    style={{ display: 'block' }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Selected Color
                </Typography>
                {selectedColor ? (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ width: 40, height: 40, bgcolor: selectedColor, mr: 2, border: '1px solid #ccc' }} />
                      <Typography variant="body1">{selectedColor}</Typography>
                      <Button 
                        startIcon={<ContentCopyIcon />}
                        onClick={() => copyToClipboard(selectedColor)}
                        sx={{ ml: 'auto' }}
                      >
                        Copy
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ width: 40, height: 40, bgcolor: selectedColor, mr: 2, border: '1px solid #ccc' }} />
                      <Typography variant="body1">{getRgbRange(selectedColor)}</Typography>
                      <Button 
                        startIcon={<ContentCopyIcon />}
                        onClick={() => copyToClipboard(getRgbRange(selectedColor))}
                        sx={{ ml: 'auto' }}
                      >
                        Copy
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Typography variant="body1">
                    No color selected. Click on the image to pick a color.
                  </Typography>
                )}
                <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                  Color Palette
                </Typography>
                <Grid container spacing={1}>
                  {colorPalette.map((color, index) => (
                    <Grid item key={index} xs={3}>
                      <Box
                        sx={{
                          width: '40%',
                          paddingBottom: '40%',
                          bgcolor: color,
                          border: '1px solid #ccc',
                          cursor: 'pointer',
                          margin: 'auto',
                        }}
                        onClick={() => setSelectedColor(color)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
};

export default ColorPickerPage;