import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import LoopIcon from '@mui/icons-material/Loop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const StyledPlayer = styled(Box)( {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#030303',
  color: '#fff',
  padding: '8px 16px',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column',
});

const PlayerControls = styled(Box)( {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ControlButtons = styled(Box)( {
  display: 'flex',
  alignItems: 'center',
});

const SongInfo = styled(Box)( {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

const VolumeControl = styled(Box)( {
  display: 'flex',
  alignItems: 'center',
  width: '150px',
});

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

function MusicPlayer({
  songs,
  currentSongIndex,
  onSongChange,
  autoPlay = false,
}) {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(100);
  const [isLooping, setIsLooping] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  const currentSong = songs[currentSongIndex];

  useEffect(() => {
    const audioElement = audioRef.current;

    const updateDuration = () => {
      setDuration(audioElement.duration);
    };

    audioElement.addEventListener('loadedmetadata', updateDuration);

    if (autoPlay && audioElement) {
      audioElement.play().then(() => setPlaying(true)).catch((err) => console.log(err));
    }

    return () => {
      audioElement.removeEventListener('loadedmetadata', updateDuration);
    };
  }, [currentSong, autoPlay]);

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(audioRef.current.currentTime);
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener('timeupdate', updateCurrentTime);

    return () => {
      audioElement.removeEventListener('timeupdate', updateCurrentTime);
    };
  }, []);

  useEffect(() => {
    setCurrentTime(0);
    setPlaying(autoPlay);
  }, [currentSong]);

  const togglePlayPause = () => {
    const isPlaying = !playing;
    setPlaying(isPlaying);
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleTimeSliderChange = (event, newValue) => {
    setCurrentTime(newValue);
    audioRef.current.currentTime = newValue;
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
    setIsMuted(newValue === 0);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audioRef.current.volume = isMuted ? volume / 100 : 0;
  };

  const handlePrevious = () => {
    const newIndex = currentSongIndex === 0 ? songs.length - 1 : currentSongIndex - 1;
    onSongChange(newIndex);
  };

  const handleNext = () => {
    const newIndex = currentSongIndex === songs.length - 1 ? 0 : currentSongIndex + 1;
    onSongChange(newIndex);
  };

  const handleToggleLoop = () => {
    setIsLooping((prevState) => !prevState);
  };

  const handleSongEnd = () => {
    if (isLooping) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      handleNext();
    }
  };

  return (
    <StyledPlayer>
      <Slider
        aria-label="time-slider"
        value={currentTime}
        min={0}
        step={1}
        max={duration}
        onChange={handleTimeSliderChange}
        sx={{
          color: '#808080', // Grey color for the rail
          height: 4,
          '& .MuiSlider-thumb': {
            backgroundColor: '#ff0000', // Red color for the thumb
            width: 12,
            height: 12,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:hover, &.Mui-focusVisible': {
              boxShadow: '0px 0px 0px 8px rgb(255 0 0 / 16%)',
            },
            '&.Mui-active': {
              width: 16,
              height: 16,
            },
          },
          '& .MuiSlider-rail': {
            opacity: 1,
            backgroundColor: '#808080', // Grey rail color
          },
          '& .MuiSlider-track': {
            backgroundColor: '#ff0000', // Red track color
          },
        }}
      />
      <PlayerControls>
        <ControlButtons>
          <IconButton onClick={handlePrevious} sx={{ color: 'white' }}>
            <SkipPreviousIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton onClick={togglePlayPause} sx={{ color: 'white', mx: 2 }}>
            {playing ? <PauseIcon sx={{ fontSize: 40 }} /> : <PlayArrowIcon sx={{ fontSize: 40 }} />}
          </IconButton>
          <IconButton onClick={handleNext} sx={{ color: 'white' }}>
            <SkipNextIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant="body2" sx={{ marginLeft: '8px' }}>
            {formatTime(currentTime)}/{formatTime(duration)}
          </Typography>
        </ControlButtons>
        <SongInfo>
          <img
            src={currentSong.album_cover || '/placeholder-image.jpg'}
            alt={currentSong.title}
            style={{ width: '40px', height: '40px', marginRight: '12px' }}
          />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {currentSong.title}
            </Typography>
            <Typography variant="body2" sx={{ color: '#aaa' }}>
              {currentSong.artist}
            </Typography>
          </Box>
        </SongInfo>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <VolumeControl sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={toggleMute} sx={{ color: 'white' }}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
            <Slider
              aria-label="volume-slider"
              value={isMuted ? 0 : volume}
              onChange={handleVolumeChange}
              sx={{
                color: 'white',
                '& .MuiSlider-track': {
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  width: 12,
                  height: 12,
                  backgroundColor: '#fff',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: `0px 0px 0px 8px rgb(255 255 255 / 16%)`,
                  },
                  '&.Mui-active': {
                    width: 16,
                    height: 16,
                  },
                },
              }}
            />
          </VolumeControl>
          <IconButton onClick={handleToggleLoop} sx={{ color: isLooping ? '#ff0000' : 'white' }}>
            <LoopIcon />
          </IconButton>
        </Box>
      </PlayerControls>
      <audio
        ref={audioRef}
        src={currentSong.file}
        onEnded={handleSongEnd}
        hidden
      />
    </StyledPlayer>
  );
}

export default MusicPlayer;
