import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, TextField, Box, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { useUser } from '../contexts/userContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PageOnboarding = () => {
  const { userInfo, updateUserInfo } = useUser(); // Using the custom hook
  const [activeStep, setActiveStep] = useState(0);
  const [userName, setUserName] = useState('');
  const [selectedPath, setSelectedPath] = useState('Aprendiz'); // Preselected option
  const [theme, setTheme] = useState('');

  const steps = ['Introduction', 'Choose Your Path', 'Choose Your Theme', 'Summary']; // Updated steps
  const navigate = useNavigate(); // Instantiate the useNavigate hook

  useEffect(() => {
    // Ensure user info is cleared at the start or maintain previous state
  }, []);

  const handleNext = async () => {
    if (activeStep < steps.length - 1) {
      // Only increment if not on the last step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === steps.length - 1) {
      // Attempt to submit the form data
      try {
        const response = await fetch('https://boringtube.azurewebsites.net/api/onboarding', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userInfo.jwtToken}`,
          },
          body: JSON.stringify({
            user_name_bt: userName,
            difficulty: selectedPath === 'Aprendiz' ? 0 : selectedPath === 'Avanzado' ? 1 : 2,
            darkmode: theme === 'Dark',
          }),
        });
  
        const jsonResponse = await response.json();
        if (jsonResponse.success) {
          window.alert('Success!');
          navigate('/flow'); // Redirect the user to /flow upon successful API response
      
        } else {
          window.alert('Failed to submit your preferences.');
        }
      } catch (error) {
        console.error('Error during API call:', error);
        window.alert('An error occurred while sending your data.');
      }
    }
  };
  

  const getStepContent = (step) => {
    switch (step) {
      case 0:
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={process.env.PUBLIC_URL + '/a.jpg'} alt="Introduction" style={{ width: '50%', marginBottom: '20px' }} />
      <TextField
        label="Tu nombre público"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        variant="outlined"
      />
      {/* The extra Next button has been removed from here */}
    </Box>
  );

      case 1:
        return (
          <Box>
            <p>Elige tu camino:</p>
            <FormControl component="fieldset">
              <RadioGroup aria-label="path" name="path" value={selectedPath} onChange={(e) => setSelectedPath(e.target.value)}>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                  {["Aprendiz", "Avanzado", "Monje"].map((path) => (
                    <FormControlLabel
                      key={path}
                      value={path}
                      control={<Radio />}
                      label={
                        <div style={{ textAlign: 'center' }}>
                          <img src={process.env.PUBLIC_URL + `/${path.toLowerCase()}.webp`} alt={path} style={{ width: '100px' }} />
                          <p>{path}</p>
                        </div>
                      }
                    />
                  ))}
                </div>
              </RadioGroup>
            </FormControl>
          </Box>
        );
      case 2:
        return (
          <Box>
            <img src={process.env.PUBLIC_URL + '/theme1.png'} alt="Theme Selection" style={{ width: '100%', marginBottom: '20px' }} />
            <FormControl component="fieldset">
              <RadioGroup aria-label="theme" name="theme" value={theme} onChange={(e) => setTheme(e.target.value)}>
                <FormControlLabel value="Light" control={<Radio />} label="Modo Claro" />
                <FormControlLabel value="Dark" control={<Radio />} label="Modo Oscuro" />
              </RadioGroup>
            </FormControl>
          </Box>
        );
      // Adjusted final step in the getStepContent function
      case 3:
        // This is the final step content without the Send button here
        return (
          <Box>
            <p>¡Bienvenido, {userName}!</p>
            <p>Has elegido el camino del {selectedPath} y el modo de tema {theme}.</p>
            <p>¡Buena suerte en tu viaje!</p>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };
  
  // ... other component code

return (
  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="white">
    <Box width="50%" sx={{ bgcolor: '#f3f3f3', padding: '20px', borderRadius: '8px' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: activeStep === 0 ? 'flex-end' : 'space-between' }}>
        {activeStep > 0 && (
          <Button variant="outlined" color="primary" onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Send
          </Button>
        )}
      </Box>
    </Box>
  </Box>
);

};

export default PageOnboarding;
