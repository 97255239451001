import React, { createContext, useContext, useState, useEffect } from 'react';

const ClickContext = createContext();

export const useClicks = () => useContext(ClickContext);

export const ClickProvider = ({ children }) => {
  // Initialize clickCount from localStorage if it exists
  const [clickCount, setClickCount] = useState(() => {
    const storedClickCount = localStorage.getItem('clickCount');
    return storedClickCount ? parseInt(storedClickCount, 10) : 0;
  });

  useEffect(() => {
    // Update localStorage whenever clickCount changes
    localStorage.setItem('clickCount', clickCount.toString());
  }, [clickCount]);

  useEffect(() => {
    const handleDocumentClick = () => {
      setClickCount((prevCount) => prevCount + 1);
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <ClickContext.Provider value={{ clickCount }}>
      {children}
    </ClickContext.Provider>
  );
};
