import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Button, Paper, Input } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileLoaderComponent = ({ 
  tool_name, 
  button_text, 
  tool_description, 
  onFileLoad,
  acceptedFileTypes = '*'
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleFile = useCallback((file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onFileLoad(file, e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [onFileLoad]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  }, [handleFile]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handlePaste = useCallback((e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        handleFile(file);
        break;
      }
    }
  }, [handleFile]);

  const handleChange = useCallback((e) => {
    const file = e.target.files[0];
    handleFile(file);
  }, [handleFile]);

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', textAlign: 'center' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black', mb: 2 }}>
        {tool_name}
      </Typography>
      <Typography variant="body1" sx={{ color: 'black', mb: 3 }}>
        {tool_description}
      </Typography>
      
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          border: '2px dashed',
          borderColor: isDragging ? 'primary.main' : 'grey.300',
          bgcolor: isDragging ? 'action.hover' : 'background.paper',
          transition: 'all 0.3s',
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <CloudUploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
        <Typography variant="body2" sx={{ mb: 2 }}>
          Drop a file here, paste from clipboard, or click the button below to select a file
        </Typography>
        <Input
          type="file"
          id="file-upload"
          sx={{ display: 'none' }}
          onChange={handleChange}
          inputProps={{ accept: acceptedFileTypes }}
        />
      </Paper>

      <Button
        variant="contained"
        component="label"
        htmlFor="file-upload"
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          borderRadius: '20px',
          padding: '10px 20px',
          fontSize: '1rem',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
        }}
      >
        {button_text}
      </Button>

      <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
        You can also use Ctrl+V to paste an image from clipboard.
      </Typography>
    </Box>
  );
};

export default FileLoaderComponent;