import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Button, Slider, Typography, Popover, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const CentralWidget = React.memo(({ mp3Link, songAttribution, onSongEnd }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(70);
  const [isMuted, setIsMuted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(mp3Link);
    audioRef.current.volume = volume / 100;
    const handleSongEnd = () => {
      setIsPlaying(false);
      onSongEnd();
    };
    audioRef.current.addEventListener('ended', handleSongEnd);

    return () => {
      audioRef.current.removeEventListener('ended', handleSongEnd);
      audioRef.current.pause();
      audioRef.current.src = ''; // Cleanup to avoid memory leaks
    };
  }, []); // Ensures this effect only runs once on mount

  useEffect(() => {
    audioRef.current.src = mp3Link;
    audioRef.current.play().then(() => {
      setIsPlaying(true);
    }).catch(error => {
      console.error("Error playing audio:", error);
      setIsPlaying(false);
    });
  }, [mp3Link]); // Reacts to changes in mp3Link
  

  useEffect(() => {
    audioRef.current.volume = isMuted ? 0 : volume / 100;
  }, [isMuted, volume]); // Adjusts volume based on mute state and volume level

  const togglePlayPause = useCallback(() => {
    setIsPlaying(prev => {
      const newState = !prev;
      newState ? audioRef.current.play().catch(error => console.error("Error playing audio:", error)) : audioRef.current.pause();
      return newState;
    });
  }, []);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    if (newValue === 0) {
      setIsMuted(true);
    } else if (isMuted) {
      setIsMuted(false);
    }
  };

  const handleAttributionClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'song-attribution-popover' : undefined;

  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: 2,
      borderRadius: '16px',
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
      backgroundColor: '#f5f5f5',
      width: '250px'
    }}
  >
    <Box sx={{ mt: 1.5 }}> {/* Increased top margin */}
      <Button onClick={togglePlayPause} sx={{ my: 4, transform: 'scale(2)' }}> {/* Adjusted to use my for margin y-axis */}
        {isPlaying ? <PauseCircleFilledIcon fontSize="large" /> : <PlayCircleFilledWhiteIcon fontSize="large" />}
      </Button>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mb: 2 }}>
      <IconButton onClick={toggleMute}>
        {isMuted || volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </IconButton>
      <Slider
        aria-label="volume-slider"
        value={isMuted ? 0 : volume}
        onChange={handleVolumeChange}
        sx={{ width: '60px', mx: 1 }} // Added margin on the sides of the slider for even spacing
        size="small"
      />
      <IconButton onClick={onSongEnd} >
        <SkipNextIcon />
      </IconButton>
    </Box>
    <Typography sx={{ cursor: 'pointer', color: 'grey' }} onClick={handleAttributionClick}>
      Song Attribution
    </Typography>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleAttributionClick}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Typography sx={{ p: 2 }}>{songAttribution}</Typography>
    </Popover>
    
  </Box>
  


);
});

export default CentralWidget;
