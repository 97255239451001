import React, { useState, useEffect } from 'react';

function PageVideos() {
  const [videos, setVideos] = useState([]);
  const [mode, setMode] = useState('Aprendiz'); // 'Aprendiz' y 'Avanzado' son los modos disponibles
  const [error, setError] = useState(''); // Estado para almacenar errores

  useEffect(() => {
    const fetchVideos = async () => {
      console.log('Iniciando la carga de vídeos...');
      setError(''); // Reinicia el estado de error antes de la nueva solicitud
      try {
        console.log('Leyendo el token JWT del sessionStorage...');
        const token = sessionStorage.getItem('jwtToken');
        console.log('Token obtenido:', token);

        if (!token) {
          throw new Error('No se encontró el token de autenticación.');
        }

        console.log('Realizando solicitud a la API...');
        const response = await fetch(`http://localhost:5000/api/videos`, {
          method: 'GET', // Asegúrate de que el método HTTP sea el correcto para tu endpoint
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' // Añade este header si tu API lo requiere
          }
        });

        console.log('Respuesta obtenida:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Datos recibidos:', data);

        if (data && data.data && data.data.videos) {
          setVideos(data.data.videos);
        } else {
          setError('Formato de datos inesperado');
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(error.message);
        console.log('Detalles del error:', error);
      }
    };

    fetchVideos();
  }, []); // Se ejecuta solo al montar el componente

  if (error) {
    return <div>Error cargando los vídeos: {error}</div>;
  }

  return (
    <div>
      <h1>Vídeos en Modo {mode}</h1>
      <button onClick={() => setMode('Aprendiz')}>Aprendiz</button>
      <button onClick={() => setMode('Avanzado')}>Avanzado</button>

      {videos.length > 0 ? (
        <div>
          {videos.map((video, index) => (
            <div key={index}>
              <p>{video.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <div>No se encontraron vídeos.</div>
      )}
    </div>
  );
}

export default PageVideos;
