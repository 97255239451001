// Import dependencies at the top
import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    return storedUserInfo ? JSON.parse(storedUserInfo) : { jwtToken: null, user_name_bt: '', mode: 0, theme: 0, birthdate: null };
  });

  useEffect(() => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));

    // Token refresh mechanism
    const checkTokenValidity = async () => {
      const currentTime = Date.now() / 1000; // Convert to seconds
      const tokenExpiration = jwtDecode(userInfo.jwtToken).exp; // Assuming your JWT has an 'exp' field

      // If the token is about to expire (less than 5 minutes remaining), attempt to refresh it
      if (tokenExpiration && tokenExpiration - currentTime < 1000000000) {
        await refreshToken();
      }
    };

    // Call this function periodically, e.g., every 5 minutes
    const intervalId = setInterval(checkTokenValidity, 1000000000); // 300,000 milliseconds = 5 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [userInfo]);

  const refreshToken = async () => {
    // Implement your token refresh logic here, possibly calling a backend endpoint to get a new token
    // For demonstration, I'm just logging to the console
    console.log('Refreshing token...');
    // On successful token refresh, update userInfo with the new token
    // setUserInfo({...userInfo, jwtToken: 'newTokenHere'});
  };

  const updateUserInfo = (updates) => {
    setUserInfo(currentState => ({ ...currentState, ...updates }));
  };

  const clearUserInfo = () => {
    setUserInfo({
      jwtToken: null,
      user_name_bt: '',
      mode: 0,
      theme: 0,
      birthdate: null,
    });
    localStorage.removeItem('userInfo');
  };

  return (
    <UserContext.Provider value={{ userInfo, updateUserInfo, clearUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};
