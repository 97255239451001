import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useFlowContent } from '../contexts/useFlowContent';
import { useUser } from '../contexts/userContext';
import CentralWidget from '../components/FlowComponents/FlowPlayerComponent';
import TimerComponent from '../components/PomodoroComponents/PomodoroComponent';
const FlowMusicPage = () => {
  const { flowMode } = useFlowContent();
  const { userInfo } = useUser();
  const [songs, setSongs] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://boringtube.azurewebsites.net/api/flow?boringtube_flow_mode=${flowMode}`, {
          headers: {
            'Authorization': `Bearer ${userInfo.jwtToken}`
          }
        });
        const data = await response.json();
        if (data.success) {
          setSongs(data.data);
          setCurrentSongIndex(0); // Reset song index whenever new songs are fetched
        } else {
          console.error("API call was unsuccessful");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [flowMode, userInfo.jwtToken]);

  const handleSongEnd = () => {
    setCurrentSongIndex(prevIndex => (prevIndex + 1) % songs.length);
  };

  return (
    <Box style={{
      height: '84vh',
      position: 'relative',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundImage: 'url(/flow_background.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      {songs.length > 0 && (
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
          <TimerComponent />
          <CentralWidget
            key={currentSongIndex} // Adding a key to force re-mount on song change
            mp3Link={songs[currentSongIndex].song_audio_link}
            songAttribution={songs[currentSongIndex].song_attribution}
            onSongEnd={handleSongEnd}
          />
        </Box>
      )}
      {/* Optionally, adjust or remove this button if flow mode change mechanism is different */}
      <Box style={{ position: 'absolute', left: 20, bottom: 20 }}>
        {/* Adjust or remove the flow mode change functionality */}
      </Box>
    </Box>
  );

};

export default FlowMusicPage;
